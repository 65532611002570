import React from "react";
import { Link } from "react-router-dom";
import Counters from "./counters";

const WhyUs = (props) => {

    return (
        <section className="why-us-section py-5">
            <div className="container">
                <div className="row">
                <div className="col-md-6 my-3">
                <h2 className="text-gray-600 text-3xl capitalize font-[500] mb-4">Our Research, your strategic Advantage</h2>
                {/* <h3 className="text-gray-700 text-2xl capitalize font-[500]">Dataworld Solutions</h3> */}

                <div className="text-gray-500 text-xl">
                    <p className="">
                        Dataworld Research is the fastest growing entity in areas of Project Design, Socio- economic research, Private Sector Development, Strategic Planning, Environmental Assessment, Monitoring and Evaluation, and Training
                    </p>
                    <p className="mb-4">
                        Dataworld Research's projects extends beyond country borders across Africa.
                    </p>

                    
                </div>
                <div className="mt-3"><Link className="block text-white py-1 px-2 rounded-md font-bold uppercase" to={"/solutions"} style={{width:"auto !important"}}><button className="btn btn-primary">Learn More</button></Link></div>
            </div>

            <div className="col-md-6 py-4">
                <Counters />
                <div className="col-md-12 text-center"><Link to={"/our-projects"} className="btn bg-maroon text-white uppercas">Go to Projects</Link></div>
            </div>
            </div>
            </div>
        </section>
    )
}

export default React.memo(WhyUs);