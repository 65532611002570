import React from "react";
import { LineChart, Line, Bar, PieChart, Pie, CartesianGrid, XAxis, YAxis, Tooltip, BarChart } from 'recharts';
import Graph1 from "../../assets/img/graphs/1.jpg"
import Graph2 from "../../assets/img/graphs/2.jpg"
const Graphics = (props) => {
    const data = [{name: 'A', uv: 400, pv: 2400, amt: 2400}, {name: 'A', uv: 400, pv: 2400, amt: 2400}, {name: 'B', uv: 200, pv: 1400, amt: 1400}, {name: 'C', uv: 100, pv: 3400, amt: 2400}, {name: 'D', uv: 400, pv: 2400, amt: 2400}];

      const data01 = [
        {
          "name": "Group A",
          "value": 400
        },
        {
          "name": "Group B",
          "value": 300
        },
        {
          "name": "Group C",
          "value": 300
        },
        {
          "name": "Group D",
          "value": 200
        },
        {
          "name": "Group E",
          "value": 278
        },
        {
          "name": "Group F",
          "value": 189
        }
      ];
      const data02 = [
        {
          "name": "Group A",
          "value": 2400
        },
        {
          "name": "Group B",
          "value": 4567
        },
        {
          "name": "Group C",
          "value": 1398
        },
        {
          "name": "Group D",
          "value": 9800
        },
        {
          "name": "Group E",
          "value": 3908
        },
        {
          "name": "Group F",
          "value": 4800
        }
      ];
    return (
        <div className="row">
            <div className="col-md-4">
                <LineChart width={400} height={200} data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                    <Line type="monotone" dataKey="uv" stroke="#2563eb" />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                </LineChart>
            </div>
            <div className="col-md-4 hidden md:block">
                <PieChart width={530} height={250}>
                    <Pie data={data01} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={50} fill="#800000" />
                    <Pie data={data02} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#2563eb" label />
                </PieChart>
            </div>
            <div className="col-md-4">
            {/* <img src={Graph1} alt="" width={"100%"}/> */}

            <BarChart width={400} height={200} data={data}>
                <XAxis dataKey="name" stroke="#800000" />
                <YAxis />
                <Tooltip />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <Bar dataKey="uv" fill="#800000" barSize={30} />
            </BarChart>
            </div>
        </div>
    )
}

export default React.memo(Graphics);