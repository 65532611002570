import React from "react";
import PageHeader from "../utils/page-header";
import { FaChartArea, FaChartPie, FaComments, FaDesktop, FaLightbulb } from "react-icons/fa";
import { Container, Row } from "react-bootstrap";
import EastAfrica from '../../assets/img/east-africa.PNG';
import { Link } from "react-router-dom";
import Research from '../../assets/img/solutions/research.jpeg';
import Training from '../../assets/img/solutions/training.jpg';
import MarketIntelligence from '../../assets/img/solutions/market-inteligence.jpg';
import Geospatial from '../../assets/img/solutions/geospatial.jpg';
import Sustainability from '../../assets/img/solutions/sustainability.jpeg';
import AnalysisReporting from '../../assets/img/solutions/analysis-reporting.jpeg';
// import Research from '../../assets/img/solutions/5.jpeg';
// import Research from '../../assets/img/solutions/5.jpeg';

const Solutions = (props) => {

    const DesktopContent = () => {

        return (
            <>
                <div className="big-images-section">
                    <div className="row">
                    <div className="col-md-6 zero-img-col">
                            <img src={Research} alt="" />
                        </div>
                        
                        <div className="col-md-6">
                            <div className="basic-boxed-content right">
                                <h2 className="text-2xl font-[500] text-gray-600 mb-3">
                                    <FaDesktop className="text-4xl  inline-block mr-3" /> Research & Evaluation
                                </h2>
                                <p>
                                    Our company, at its core, is a specialized research firm with a strong geographic focus. From standard project evaluations, to baseline, midterm, and end-term assessments, our field monitors and analysts are prepared to deliver comprehensive data sets and rigorous analyses in accordance with your needs. Our Research Assistants are regularly trained and are well-versed in collecting all manners of data, whether qualitative or quantitative. From focus group discussions to key informant interviews, our analysts are prepared to examine and synthesize the data you need into clear, concise reports to keep you informed.
                                </p>
                            </div>
                        </div>
                        
                        
                        
                        <div className="col-md-6">
                            <div className="basic-boxed-content left">
                                <h2 className="text-2xl font-[500] text-gray-600 mb-3">
                                    <FaChartArea className="text-4xl  inline-block mr-3" /> Analysis and Reporting
                                </h2>
                                <p>
                                Dataworld Research takes seriously the need to protect information and data on behalf of our clients and the communities we work with. Data protection measures are typically stipulated by an in-house standard operating procedure (SOP) emphasizing the client’s confidentiality measures. DR stands ready to implement any and all measures concerning sensitive information, including SOPs regarding multiple-step rights access verification, password protection, and data encryption during transmission, archiving, storage, and retrievals.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 zero-img-col">
                            <img src={AnalysisReporting} alt="" />
                        </div>

                        <div className="col-md-6 zero-img-col">
                            <img src={Sustainability} alt="" />
                        </div>
                        <div className="col-md-6">
                            <div className="basic-boxed-content right">

                                <h2 className="text-2xl font-[500] text-gray-600 mb-3">
                                    <FaLightbulb className="text-4xl  inline-block mr-3" /> Sustainability Advisory Services
                                </h2>
                                <p>
                                    We are in business of potential economic communities achieve their goals by integrating what we do with their businesses, in bid to infuse values that will boost their image and marketing opportunities. We achieve this by integrating a range of strategies aiming to establish markets that function vibrantly and fairly, providing economic opportunities of quality to ambitious poor people at scale.
                                </p>
                            </div>
                        </div>
                        
                        <div className="col-md-6">
                            <div className="basic-boxed-content left">
                                <h2 className="text-2xl font-[500] text-gray-600 mb-3">
                                    <FaChartPie className="text-4xl  inline-block mr-3" />Geospatial Data Capture
                                </h2>
                                <p>
                                Beginning with a capacity assessment, our capacity building services help NGOs, public sector, and private sector actors develop their operational capacity and reach in a variety of ways. Dataworld Research has the organizational expertise to help your organization maximize its presence, services, and efficiency.                            </p>
                            </div>
                        </div>
                        <div className="col-md-6 zero-img-col">
                            <img src={Geospatial} alt="" />
                        </div>
                        <div className="col-md-6 zero-img-col">
                            <img src={MarketIntelligence} alt="" />
                        </div>
                        <div className="col-md-6">
                            <div className="basic-boxed-content right">
                                <h2 className="text-2xl font-[500] text-gray-600 mb-3">
                                    <FaChartPie className="text-4xl  inline-block mr-3" /> Market Intelligence
                                </h2>
                                <ul className="list-disc">
                                    <li className="py-2">Monitoring, Verification and Evaluations</li>
                                    <li className="py-2">Social Impact Assessments</li>
                                    <li className="py-2">Community Needs Assessments</li>
                                    <li className="py-2">Socio economic Surveys</li>
                                    <li className="py-2">Scoping Studies</li>
                                    <li className="py-2">Opinion Polling</li>
                                    <li className="py-2">Employees Satisfactory Surveys</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="basic-boxed-content left">
                            <h2 className="text-2xl font-[500] text-gray-600 mb-3">
                                <FaComments className="text-4xl  inline-block mr-3" /> Traning
                            </h2>
                            <p>
                                Our company, at its core, is a specialized research firm with a strong geographic focus. From standard project evaluations, to baseline, midterm, and end-term assessments, our field monitors and analysts are prepared to deliver comprehensive data sets and rigorous analyses in accordance with your needs. Our Research Assistants are regularly trained and are well-versed in collecting all manners of data, whether qualitative or quantitative. From focus group discussions to key informant interviews, our analysts are prepared to examine and synthesize the data you need into clear, concise reports to keep you informed.
                            </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={Training} alt="" />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const MobileContent = () => {

        return (
            <>
            <div className="container">
                <div className="row">
                <div className="solution-box col-sm-12 my-2">
                    <h2 className="text-2xl font-[500] text-gray-600 mb-3">
                                <FaDesktop className="text-4xl  inline-block mr-3" /> Research & Evaluation
                            </h2>
                    <div className="col-md-6">
                        <img src={Research} alt="" />
                    </div>
                    
                    <div className="col-md-6">
                        <div className="">
                            
                            <p>
                                Our company, at its core, is a specialized research firm with a strong geographic focus. From standard project evaluations, to baseline, midterm, and end-term assessments, our field monitors and analysts are prepared to deliver comprehensive data sets and rigorous analyses in accordance with your needs. Our Research Assistants are regularly trained and are well-versed in collecting all manners of data, whether qualitative or quantitative. From focus group discussions to key informant interviews, our analysts are prepared to examine and synthesize the data you need into clear, concise reports to keep you informed.
                            </p>
                        </div>
                    </div>
                    </div>
                    
                    <div className="solution-box col-sm-12 my-2">
                        <h2 className="text-2xl font-[500] text-gray-600 mb-3">
                                    <FaChartArea className="text-4xl  inline-block mr-3" /> Analysis and Reporting
                                </h2>
                        <div className="col-md-6">
                            <img src={AnalysisReporting} alt="" />
                        </div>
                        <div className="col-md-6">
                        <div className="">
                           
                            <p>
                            Dataworld Research takes seriously the need to protect information and data on behalf of our clients and the communities we work with. Data protection measures are typically stipulated by an in-house standard operating procedure (SOP) emphasizing the client’s confidentiality measures. DR stands ready to implement any and all measures concerning sensitive information, including SOPs regarding multiple-step rights access verification, password protection, and data encryption during transmission, archiving, storage, and retrievals.
                            </p>
                        </div>
                    </div>
                    </div>

                    <div className="solution-box col-sm-12 my-2">
                        <h2 className="text-2xl font-[500] text-gray-600 mb-3">
                            <FaLightbulb className="text-4xl  inline-block mr-3" /> Sustainability Advisory Services
                        </h2>
                        <div className="col-md-6">
                            <img src={Sustainability} alt="" />
                        </div>
                        <div className="col-md-6">
                            <div className="">

                                
                                <p>
                                    We are in business of potential economic communities achieve their goals by integrating what we do with their businesses, in bid to infuse values that will boost their image and marketing opportunities. We achieve this by integrating a range of strategies aiming to establish markets that function vibrantly and fairly, providing economic opportunities of quality to ambitious poor people at scale.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="solution-box col-sm-12 my-2">
                        <h2 className="text-2xl font-[500] text-gray-600 mb-2 uppercase">
                            <FaChartPie className="text-4xl  inline-block mr-3" />Geospatial Data Capture
                        </h2>
                        <div className="col-md-6">
                            <img src={Geospatial} alt="" />
                        </div>

                        <div className="col-md-6">
                            <div className="">
                                
                                <p>
                                Beginning with a capacity assessment, our capacity building services help NGOs, public sector, and private sector actors develop their operational capacity and reach in a variety of ways. Dataworld Research has the organizational expertise to help your organization maximize its presence, services, and efficiency.                            </p>
                            </div>
                        </div>
                    </div>


                    <div className="solution-box col-sm-12 my-2">
                        <h2 className="text-2xl font-[500] text-gray-600 mb-2 uppercase">
                            <FaChartPie className="text-4xl  inline-block mr-3" /> Market Intelligence
                        </h2>
                        <div className="col-md-6">
                            <img src={MarketIntelligence} alt="" />
                        </div>
                        <div className="">
                            <div className="">
                                <ul className="list-disc">
                                    <li className="py-2">Monitoring, Verification and Evaluations</li>
                                    <li className="py-2">Social Impact Assessments</li>
                                    <li className="py-2">Community Needs Assessments</li>
                                    <li className="py-2">Socio economic Surveys</li>
                                    <li className="py-2">Scoping Studies</li>
                                    <li className="py-2">Opinion Polling</li>
                                    <li className="py-2">Employees Satisfactory Surveys</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="solution-box col-sm-12 my-2">
                        <h2 className="text-2xl font-[500] text-gray-600 mb-2 uppercase">
                            <FaComments className="text-4xl  inline-block mr-3" /> Traning
                        </h2>
                        <div className="col-md-6">
                            <img src={Training} alt="" />
                        </div>
                        <div className="col-md-6">
                            
                            <p>
                                Our company, at its core, is a specialized research firm with a strong geographic focus. From standard project evaluations, to baseline, midterm, and end-term assessments, our field monitors and analysts are prepared to deliver comprehensive data sets and rigorous analyses in accordance with your needs. Our Research Assistants are regularly trained and are well-versed in collecting all manners of data, whether qualitative or quantitative. From focus group discussions to key informant interviews, our analysts are prepared to examine and synthesize the data you need into clear, concise reports to keep you informed.
                            </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </> 
        )
    }
    return (
        <>
            <PageHeader title={"Our Solutions"} />

            <section className="mt-1 ">
                <Container className="py-3 border-b border-gray-200">

                    <Row>
                        <div className="col-md-6 col-sm-12">
                            <h1 className="text-4xl mb-3 pb-3 border-b border-gray-200 text-blue-800 font-[500]">Region of Operation</h1>
                            <p className="text-2xl text-gray-500">Our research extends to greater parts of Africa with a specialization in the 6 research services</p>
                            <p className="text-2xl text-gray-500 my-4 font-normal">The services include:<br/>Research and Evaluation, Market Intelligence, Sustainability Advisory Services, Geospatial Data Capture , Analysis and Reporting and Training</p>

                            <div><Link to={"/data"}> <button className="btn bg-maroon !text-white font-[500] capitalize">Inquire here</button></Link></div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <img src={EastAfrica} alt="map"/>
                        </div>
                    </Row>
                </Container>
            </section>
            <section className="my-4">
                <div className="hidden md:block"><DesktopContent /></div>
                <div className="md:hidden"><MobileContent /></div>
            </section>
        </>
    )
}

export default React.memo(Solutions);