import React, { useState } from "react";
import PageHeader from "../utils/page-header";
import { FaStar } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Coverage from "../../assets/img/coverage.png";
import AboutImg from "../../assets/img/about/about-group.jpg";
import Partnerships from "../sections/partnerships";
import Values from "../../assets/img/about/values.png"
import { Link } from "react-router-dom";

const About = (props) => {
    const [showReadmoreModal, setShowReadmoreModal] = useState(false)
    return (
        <>
            {/* <PageHeader title="About" /> */}
            <section className="intro-block relative bg-blue-600">
            <section className="about-intro-overlay"></section>
            <section className="about-intro">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="intro-border-bottom-curl">
                                <h1 className="text-2xl capitalize">Our story</h1>
                                <p>
                                    Dataworld Research, one of the fastest growing Kenyan consulting firm, offers the best local expertise available in Kenya– with the right level of relationships, insight and scalable support staff.
                                </p>
                                <p className="cursor-pointer italic font-[500]" onClick={() => setShowReadmoreModal(true)}>Read More</p>

                                {/* Modal to show more  */}
                                <Modal size="lg" show={showReadmoreModal} onHide={() => setShowReadmoreModal(false)}>
                                    <Modal.Header closeButton>
                                    <Modal.Title>Our Story</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <p>Dataworld Research, one of the fastest growing Kenyan consulting firm, offers the best local expertise available in Kenya– with the right level of relationships, insight and scalable support staff. Dataworld Research is a Kenyan-based professional service firm with a global experience. It is a specialized team of professionals with competency including Project Design, Socio- economic research, Private Sector Development, Strategic Planning, Environmental Assessment, Monitoring and Evaluation, and Training.</p>
                                        <p>Dataworld Research was founded by a group of professionals from diverse backgrounds and with broad expertise, having an extensive and solid research and training consulting experience in the region. With its headquarters in Nairobi, Kenya, Dataworld offers local skills, knowledge, and experience necessary to support national initiatives of large scale and scope.</p>
                                        <p>Dataworld Research was established in 2007 to focus on small research activities in Nairobi, but has now grown to supporting organizations all over Africa. Our head office is in Nairobi. The firm is legally registered in Kenya (CPR/2012/90780) and has worked on projects in more than 5 countries around Africa.</p>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowReadmoreModal(false)}>
                                        Close
                                    </Button>
                                    
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </section>
            <section className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Coverage} alt="coverage" style={{maxHeight:"450px"}}/>                      
                        </div>

                        <div className="col-md-6">
                            <h2 className="text-2xl text-gray-600 my-2">KEY FOCUS AREAS</h2>

                            <div className="mb-3">
                                With management and support teams in our Nairobi office overseeing field operations in all locations across Kenya, Dataworld Research Ltd is placed well to respond swiftly to today’s pressing research needs in Kenya and beyond. Our total sphere of operations in East Africa includes Kenya, Somalia, Rwanda, Ethiopia, Tanzania, DRC, Burundi and South Sudan. Dataworld Research maintains staff of nearly 50 Research Assistants across country’s myriad dialects, diverse cultures, and vast sub-regions, allowing us access to the entire country. We invest heavily in our staff members, as we understand that they maintain the key to our success. They are regularly trained, updated, and incentivized to perform at the highest levels.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-bg-with-overlay bg-gray-100">
            <section className="sillouhoutte-overlay"></section>
            <section className="why-choose-us pt-4 relative">
                <section className=""><div className="container py-4 text-2xl"><h1 className="text-center uppercase text-gray-600">Why choose us</h1></div></section>
                
                <section id="about">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <p>Dataworld Research offers market and social research services that include designing surveys, collecting data, analyzing the data, and presenting the findings. The company has a strong presence in the African research space.</p>
                                <p>In our rapidly changing world, the need for reliable information to make confident decisions has never been greater. For this reason, our passionate research experts strive to provide the most precise measurements and shape them to give a true Understanding of Society, Markets, and People. This way our clients can act faster, smarter, and bolder. Here’s why you should choose us.</p>
                            </div>

                            <div className="col-md-6">
                                <img src={AboutImg} alt=""/>
                            </div>
                        </div>
                    </div>

                    <div className="container py-3">
                        <div className="row">
                            <div className="col-md-4">
                                <h1 className="about-carousel-title">Country Presence</h1>
                                <div className="about-carousel-box">
                                Dataworld Research is based in Nairobi, Kenya but has a significant field presence in various Sub-Saharan African countries, including South Sudan, Somalia, Ethiopia, Rwanda, Uganda, Tanzania, Burundi, and the Democratic Republic of the Congo, as well as in the 47 counties within Kenya.
                                </div>
                            </div>
                            <div className="col-md-4">
                                <h1 className="about-carousel-title">Experience</h1>
                                <div className="about-carousel-box">
                                <p>Dataworld Research has extensive experience in social research, having been in operation since 2012. During this time, the company has successfully completed a variety of projects that encompass a range of topics and themes. These projects include baseline, midline, and end line surveys, impact evaluations, and diagnostic studies that inform program development.&nbsp;</p>                                </div>
                            </div>
                            <div className="col-md-4">
                                <h1 className="about-carousel-title">Excellence</h1>
                                <div className="about-carousel-box">
                                    <p>We listen carefully to the clients problems and develop custom solutions for them.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <div className="container">
                <h2 className="text-2xl text-gray-600 my-2">Mission</h2>
                    <div className="text-gray-400 text-xl mb-4">
                        “To provide industry-leading research services to both national and international NGOs, multilaterals, private interests, and government agencies, to enable them to make the best decisions possible”
                    </div>

                    <h2 className="text-2xl text-gray-600 my-2">Our Values</h2>
                    <div className="row mb-5">
                        <ul className="col-md-6">
                            <li className=""><FaStar className="inline-block mr-2"/>Integrity and Stewardship </li>
                            <li className=""><FaStar className="inline-block mr-2"/>Responsibility </li>
                            <li className=""><FaStar className="inline-block mr-2"/>Loyalty and Commitment</li>
                            <li className=""><FaStar className="inline-block mr-2"/>Integrity and Stewardship</li>
                            <li className=""><FaStar className="inline-block mr-2"/>Trust </li>
                        </ul>
                        <ul className="col-md-6">
                            <li className=""><FaStar className="inline-block mr-2"/>Lawfulness</li>
                            <li className=""><FaStar className="inline-block mr-2"/>Openness and Respect</li>
                            <li className=""><FaStar className="inline-block mr-2"/>Learning</li>
                            <li className=""><FaStar className="inline-block mr-2"/>Continuous Learning</li>
                            <li className=""><FaStar className="inline-block mr-2"/>Leadership</li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-md-6 pr-7">
                            <img src={Values} alt="" className="pr-5"/>
                        </div>
                        <div className="col-md-6">
                            <h2 className="text-2xl text-gray-600 my-2">Our Values</h2>
                            <p>Dataworld Research Ltd is committed to and fully subscribes to the professional principles of practice as outlined in the Africa Evaluation Guidelines (2004), namely:</p>
                            <ol className="std-list" style={{listStyle:"decimal"}}>
                                <li>Meeting the clients’ requirements Ensuring that the results meet the information needs of intended users and be owned by the stakeholders.</li>
                                <li>Integrity and objectivity Our report findings ensure that information is technically adequate and accurate with respect to the situation on the group</li>
                                <li>Responsibility at all stages of the consultancy We ensure that legal and ethical issues are part of the consultancy and assume responsibility with our clients for our work.</li>
                                <li>Participatory approaches and transfer of skills Ensure that results are feasible, realistic and diplomatic.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            </section>
            <section className="about-bottom">
                <div className="container py-4">
                    <h1 className="text-blue-600 text-2xl py-3 text-center">Our Partners</h1>
                    <Partnerships />
                    <Link to={"/partners"}> </Link>
                </div>
            </section>
        </>
    )
}

export default React.memo(About)