import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {Navbar} from "react-bootstrap";
import { IoIosMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import Ticker from 'react-ticker';
import Logo from "../../assets/logo.png";
import MobileToggle from './mobile-toggle';
import { useNavigate, useNavigation } from 'react-router-dom';

const HeaderNav = React.lazy(() => import('./header-nav'));

const Header = (props) => {
    const navigate = useNavigate();
    return (
        <>
            <div className='header'>
                <section className='top-bar py-2 text-white'>
                    <div className='container text-right'>
                        <div className=''>
                            <ul className='list-none'>
                            <Ticker>
                                {({ index }) => (
                                    <>
                                        <li className='mr-4 inline-block list-none'><IoIosMail className='inline-block font-bold mr-2 ml-3'/> <span className='font-bold'> Email:</span> info@dataworldresearch.co.ke</li>
                                        <li className='inline-block list-none'><FaPhoneAlt className='inline-block font-bold mr-2  ml-3'/> <span className='font-bold'> Call Us:</span> +254-721-953790  OR +2540202523200</li>
                                    </>
                                )}
                            </Ticker>
                            </ul>
                        </div>
                    </div>
                </section>
            <Navbar expand="md" className="block pb-0" variant="white">
                <Container className={'!block'}>
                    
                    <Row className={``}>
                        <div className='col-6'>
                            <a href='/'>
                                <img className='inline-block logo' src={Logo} alt="dataworld research" title="dataworld" effects="blur"/>
                            </a>
                        </div>
                        <div className='col-6'><button className='btn bg-maroon !text-white float-end' onClick={() => navigate("/contacts")}>Make Inquiry</button></div>
                    </Row>

                </Container>
                <section className='bottom-nav mt-2'>
                    <Container className=''>
                        <Row><div className='md:hidden'><MobileToggle /></div><div className='col-12 text-right mt-2 hidden md:block'><HeaderNav /></div></Row>
                    </Container>
                </section>
            </Navbar>
            </div>
        
        
        </>

    )
}
export default React.memo(Header);
