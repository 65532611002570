import React from "react";
import { VerticalTicker, HorizontalTicker } from "react-infinite-ticker";
import { Link } from "react-router-dom";

const Partnerships = () => {
    // let imgPath = require(`../../assets/img/partnerships/omart.jpg`);
    let img = require(`../../assets/img/bpl.png`)
    const images = [
        require(`../../assets/img/partnerships/acal.png`),
        require(`../../assets/img/partnerships/gibb.png`),
        require(`../../assets/img/partnerships/hand.png`),
        require(`../../assets/img/partnerships/ibtc.png`),
        require(`../../assets/img/partnerships/IMG-20220928-WA0011.jpg`),
        require(`../../assets/img/partnerships/iussp.png`),
        require(`../../assets/img/partnerships/kalro.png`),
        require(`../../assets/img/partnerships/keet.png`),
        require(`../../assets/img/partnerships/hand.png`),
        require(`../../assets/img/partnerships/kenya-redcross.png`),
        require(`../../assets/img/partnerships/kgga.png`),
        require(`../../assets/img/partnerships/lisp.png`),
        require(`../../assets/img/partnerships/ormat.jpg`),
        require(`../../assets/img/partnerships/st-paul.png`),
        require(`../../assets/img/partnerships/unhcr.png`),
        require(`../../assets/img/partnerships/global digital.png`),
        require(`../../assets/img/partnerships/global fairness.png`),
        require(`../../assets/img/partnerships/health right.png`),
        require(`../../assets/img/partnerships/welt hunger.png`),
        require(`../../assets/img/partnerships/oxfam.png`),
        require(`../../assets/img/partnerships/sewa.png`),
        require(`../../assets/img/partnerships/windle.png`),
        require(`../../assets/img/partnerships/church world.png`),

      ];
    return (
            <div className="container">
                <div className="tracker-img-sizer mt-3" style={{marginLeft:"auto", marginRight:"auto"}}>
                    <HorizontalTicker duration={65000}>
                        {images.map((img, idx) => (
                            <>
                                <img src={img} alt={img}/>
                            </>
                        ))}
                        {/* <img src={img} alt=""/> */}
                    </HorizontalTicker>
                </div>
            </div>
    )
}

export default React.memo(Partnerships);