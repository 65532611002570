import React from "react";
import PageHeader from "../utils/page-header";
import { Link } from "react-router-dom";


const Data = (props) => {

    return (
        <>
            <PageHeader title={"Data"}/>
            <section>
                <div className="container">
                    
                </div>
            </section>

            <section className="my-2">
                <div className="container">
                    <div className="my-2 font-normal text-gray-500">The company from time to time would be making available data for purchase at competitive prices</div>
                    <div className="font-normal">In case you dont find your desired data, you can <Link className="font-bold text-blue-500 hover:underline" to={"/contacts"}>contact us directly</Link></div>
                </div>
            </section>
        </>
    )
}

export default React.memo(Data);