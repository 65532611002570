import React from 'react';
import { Row } from 'react-bootstrap';
import { FaEnvelope, FaFacebook, FaLinkedin, FaMap, FaMapMarkerAlt, FaMarker, FaMobile, FaPhone, FaPhoneAlt, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const Footer = (props) => {

    return (
        <footer className="">
            <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4 py-3'>
                            <h3 className='footer-title'>Who We are</h3>
                            <div pagelayer-id="" className="pb-3">
                                <p>Dataworld Research is a Kenyan-based professional service firm with a global experience. It is a specialized team of professionals with competency including Project Design, Socio- economic research, Private Sector Development, Strategic Planning, Environmental Assessment, Monitoring and Evaluation, and Training.</p>
                            </div>
                            <div className='flex'>
                                 <a href='https://www.linkedin.com/in/dataworld-research-9b80a9200/' target='_blank' rel='noreferrer'><FaLinkedin className='inline-block mr-2 text-gray-100' size={30}/></a>
                                 <a href='https://api.whatsapp.com/send?phone=254735820581' target='_blank' rel='noreferrer'><FaWhatsapp className='inline-block mr-2 text-gray-100' size={30}/></a>
                                 <a href='https://twitter.com/Dataworld_Ke' target='_blank' rel='noreferrer'><FaTwitter className='inline-block mr-2 text-gray-100' size={30}/></a>
                                 <a href='https://www.facebook.com/dataworld-research-9b80a9200/' target='_blank' rel='noreferrer'><FaFacebook className='inline-block text-gray-100' size={30}/></a>
                            </div>
                        </div>    
                        <div className='col-md-4 py-3'>
                            <h3 className='footer-title'>Quick Links</h3>
                            <ul>
                            <li className='list-none'><Link to={"/"}>Home</Link></li>
                            <li className='list-none'><Link to={"/data"}>Data</Link></li>
                            <li className='list-none'><Link to={"/about"}>Our Story</Link></li>
                            <li className='list-none'><Link to={"/solutions"}>Our Solutions</Link></li>
                            <li className='list-none'><Link to={"/team"}>Our Team</Link></li>
                            <li className='list-none'><Link to={"/contacts"}>Contact</Link></li>
                            <li className='list-none'><Link to={"/privacy"}>Privacy Policy</Link></li>
                            </ul>
                        </div>
                        <div className='col-md-4 py-3'>
                            <h3 className='footer-title'>Contacts</h3>
                            <div className='flex mt-2'><FaMapMarkerAlt className='inline-block mr-2 text-2xl' />Shepherds Foundation Plaza, 4th floor WingA, Opposite Buruburu Police Station; P.O Box 1984-00200 Nairobi</div>
                            <div className='flex my-2'><FaPhoneAlt className='inline-block mr-2 text-2xl'/>+2540202523200</div>
                            <div className='flex my-2'><FaPhoneAlt className='inline-block mr-2 text-2xl'/>+254-721-953790</div>
                            <div className='flex'><FaEnvelope className='inline-block mr-2 text-2xl'/>info@dataworldresearch.co.ke</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='copyright'>
                <div className='container'>
                    <Row>
                        <div className='col-md-12 text-center'>
                             © {(new Date().getFullYear())} Dataworld | For data research
                        </div>
                    </Row>
                </div>
            </section>
        </footer>
    )
}

export default Footer
