import React from "react";

const PageHeader = ({title}) => {

    return (
        <section className="page-header">
            <div className="container capitalize">{title ? title: ""}</div>
        </section>
    )
}

export default React.memo(PageHeader)