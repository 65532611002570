import React from "react";
import { Link } from "react-router-dom";


const ShopLinkButton = ({text}) => {

    return (
        <>
           <span className="!w-auto"><Link to={"/data"} ><button className="btn bg-maroon text-white">{text ? text : "Go to Data"}</button></Link></span>
        </>
    )
}

export default React.memo(ShopLinkButton);