import 'bootstrap/dist/css/bootstrap.css';
import React, {useEffect, useCallback, Suspense, useContext} from "react";
import { createRoot } from 'react-dom/client';

import {
    BrowserRouter,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom'

import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/application.css';
import './assets/css/tolkits.css';
import './index.css';
import Store from './context/store';

import Index from './components/index';

import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import { Context } from './context/store';
import About from './components/pages/about';
import Solutions from './components/pages/solutions';
import Team from './components/pages/team';
import Projects from './components/pages/projects';
import Contacts from './components/pages/contacts';
import Privacy from './components/pages/privacy';
import Fourzerofour from './components/pages/fourzerofour';
import Data from './components/pages/data';
import TawkTo from './components/tawk-to';

const Logout = () => {
    let navigate = useNavigate();
    localStorage.clear();
    const out = useCallback(() => {
        localStorage.clear();
        // window.location.href = "/"; 
    }, []);
    useEffect(() => {
        out();
    }, [out]);
    localStorage.clear();
    navigate("/");

    return null
}

const container = document.getElementById("app");


const App = () => {
    const [state, ] = useContext(Context);
    return (
            <BrowserRouter>
                <Suspense fallback={<p></p>}>
                <Header />
                <div className="main">
                    <Routes>
                        <Route exact path="/" element={<Index/>}/>
                        <Route exact path="/about" element={<About />}/>
                        <Route exact path="/solutions" element={<Solutions />}/>
                        <Route exact path="/team" element={<Team />}/>
                        <Route exact path="/our-projects" element={<Projects />}/>
                        <Route exact path="/contacts" element={<Contacts />}/>
                        <Route exact path="/privacy" element={<Privacy />}/>
                        <Route exact path="/data" element={<Data />}/>
                        <Route path="*" element={<Fourzerofour/>}/>
                    </Routes>
                </div>
                
                <Footer />
                
                </Suspense>
            </BrowserRouter>
    )

    

}



const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Store><App /></Store>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
