import React from "react";
import PageHeader from "../utils/page-header";

const Fourzerofour = (props) => {

    return (
        <>
            <PageHeader title={"Comming soon"} />
            <section>
                <div className="container">
                    <div className="text-5xl text-gray-400 font-bold">404</div>

                    <div className="my-4 text-gray-400 p-4 border-dashed border-2 border-gray-300 text-3xl">
                        The page will be available soon
                    </div>
                </div>
            </section>
        </>
    )
}

export default React.memo(Fourzerofour);