import React from "react";
import PageHeader from "../utils/page-header";
import { FaEnvelope, FaMapMarker, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import GoogleMapReact from 'google-map-react';
import googleImg from '../../assets/img/banner/location.PNG'

const Contacts = (props) => {
    const AnyReactComponent = ({ text }) => <div>{text}</div>;


    const defaultProps = {
        center: {
          lat: 10.99835602,
          lng: 77.01502627
        },
        zoom: 11
      };

    return (
        <>
            <PageHeader  title={"Contacts"}/>
            <section className="my-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="pr-2">
                                <h1 className="text-3xl text-gray-600 font-[500] my-3">Send Message</h1>
                                <div className="">
                                    <div className="row">
                                        <div className="w-1/2 px-2"><input className="py-2 form-control border-b border-gray-300" placeholder="Name*" /></div>
                                        <div className="w-1/2 px-2"><input className="py-2 form-control border-b border-gray-300" placeholder="Email*" /></div>
                                        <div className="w-1/2 px-2"><input className="py-2 form-control border-b border-gray-300" placeholder="Subject*" /></div>
                                        <div className="w-1/2 px-2"><input className="py-2 form-control border-b border-gray-300" placeholder="Phone*" /></div>
                                        <div className="w-full px-2"><textarea className="py-2 form-control" placeholder="Enter Subject*"></textarea></div>
                                        <div><button className="btn btn-primary uppercase !w-auto">Send</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                        <div className="pl-2 bg-gr200 p-3 rounded-sm">
                                <h1 className="text-3xl text-gray-600 font-[500] my-2">Direct Contact</h1>
                                <div className="">
                                    <div className="row">
                                        <div className="w- px-2">
                                            <div className="bot-sep-box">
                                                <h5 className="font-[500] capitalize my-2">Address</h5>
                                                <div className="flex mb-1"><FaMapMarkerAlt className="inline-block text-l mr-2 text-gray-500" />Shepherds Foundation Plaza, 4th floor WingA, Opposite Buruburu Police Station, P.O Box 1984-00200 Nairobi</div>
                                            </div>
                                            <div className="bot-sep-box">
                                                <h5 className="font-[500] capitalize my-2">Phone</h5>
                                                <div className="flex mb-1"><FaPhoneAlt className="inline-block text- mr-2 text-gray-500" /> +2540202523200</div>
                                                <div className="flex mb-1"><FaPhoneAlt className="inline-block text- mr-2 text-gray-500" /> +254-721-953790</div>
                                            </div>
                                            <div className="bot-sep-box">
                                                <h5 className="font-[500] capitalize my-2">email</h5>
                                                <div className="flex mb-3"><FaEnvelope className="inline-block text-2xl mr-2 text-gray-500" /> info@dataworldresearch.co.ke</div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="googleMap">
             <img src={googleImg} className="" alt=""/>
            </section>
        </>
    )
}

export default React.memo(Contacts);