import React, { 
    useContext, 
    useEffect, 
    useCallback, 
    useState, 
    useRef
} from "react";
import Partnerships from "./sections/partnerships";
import WhyUs from "./sections/why-us";
import Graphics from "./sections/graphics";
import { Link } from "react-router-dom";
import ShopLinkButton from "./sections/shop-link-button";
import CoreActivitiesNav from "./sections/core-activities-nav";


const CarouselLoader = React.lazy(() => import('./carousel/index'));


const Index = (props) => {

    return (

        <>
            <section className="carousel-section">
                <div className="container">
                    <CarouselLoader />
                </div>
                
            </section>

            <div className="hidden md:block"><CoreActivitiesNav /></div>

            <section className="py-5 graphs-section">
                <div className="container text-center">
                    <h1 className="capitalize border-b border-gray-200 mb-2 pb-2 text-3xl text-gray-700 font-[500]">Data Driven Research</h1>
                    <div className=""><p className="text- text-gray-500 ">Our Research is mainly data driven or so to put evidence based research done with the ultimate objective of finding solutions to the drivers that inform research.</p> </div>
                    <section className="py-4"><Graphics /></section>
                    <p className="text-xl text-gray-500">Dataworld research is your ultimate evidence-based research partners</p><p><ShopLinkButton text={"Go to Data"}/></p>
                </div>
            </section>
            <section className="border-b border-gray-100"><WhyUs /></section>
            <section className="py-5"><Partnerships /></section>
        </>


    )
}

export default Index
