import React, { useContext, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FaArrowDown, FaBars } from "react-icons/fa";
import ListGroup from 'react-bootstrap/ListGroup';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

function MobileToggle() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const pathname = window.location.pathname;
  
  
  return (
    <div id="navbar-collapse-main"
    className={`d-sm-flex d-flex flex-row  header-menu`}>
      <ListGroup as="ul" xs="12" horizontal className={`font-bold capitalize nav navbar-nav og d-flex ale ss  col-lg-12 col-md-12 col-sm-12 change-display`}>
                    
                    <li className={pathname === '/' ? "active" : ''}>
                        <Link className="cg fm ox anl url-link not-selectable " to={"/"} title="Home">Home</Link>
                    </li>
                    <li className={pathname === '/data' ? "active" : ''}>
                        <Link className="cg fm ox anl url-link not-selectable " to={"/data"} title="Data">Data</Link>
                    </li>
                    <li className={pathname === '/solutions' ? 'active' : ''}>
                        <Link className="cg fm ox anl url-link" to={"/solutions"}>
                            solutions
                        </Link>
                    </li>
                    <li  className={['/about', '/contacts', "/projects"].includes(pathname) ? 'active' : ''} onClick={handleShow} >More <FaBars variant="toggle-menu" size={15} className='inline-block'/></li>
                </ListGroup>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Dataworld</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            {/* The  main menu*/}
            <div className='off-canvas-content-box'>
            <ListGroup as="ul" xs="12" horizontal className={`font-bold capitalize nav navbar-nav og d-flex ale ss  col-lg-12 col-md-12 col-sm-12 change-display`}>
                    
                    <li className={`${pathname === '/' ? "active" : ''} block`} onClick={handleClose}>
                        <Link className="cg fm ox anl url-link not-selectable " to={"/"} title="Home">Home</Link>
                    </li>
                    <li className={`${pathname === '/data' ? "active" : ''}  block`} onClick={handleClose}>
                        <Link className="cg fm ox anl url-link not-selectable " to={"/data"} title="Home">Data</Link>
                    </li>
                    <li className={`${pathname === '/about' ? "active" : ''}  block`} onClick={handleClose}>
                        <Link className={`cg fm ox anl url-link`} to={"/about"}
                           title="about">About</Link>
                    </li>

                    <li className={pathname === '/solutions' ? 'active' : ''} onClick={handleClose}>
                        <Link className="cg fm ox anl url-link" to={"/solutions"}>
                            Our solutions
                        </Link>
                    </li>
                    <li className={pathname === '/team' || pathname.includes("team") ? 'active' : ''} onClick={handleClose}>
                        <Link className="g url-link" to={"/team"}>
                            our Team
                        </Link>
                    </li>
                   
                    <li className={pathname === '/our-projects' || pathname.includes("our-projects") ? 'active' : ''} onClick={handleClose}>
                        <Link className="g url-link" to={"/our-projects"}>
                            Our projects
                        </Link>
                    </li>
                    <li className={pathname === '/contacts' || pathname.includes("contacts") ? 'active' : ''} onClick={handleClose}>
                        <Link className="g url-link" to={"/contacts"}>
                            Contacts
                        </Link>
                    </li>
                </ListGroup>
            </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default MobileToggle;