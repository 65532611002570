import React from "react";
import { Row } from "react-bootstrap";


export default function CoreActivitiesNav() {

    return (

        <section className="core-activities">
            <div className="container py-2">
                <Row>
                    <div className="col-2">
                        <div className="border-r-dashed border-white flex">
                            <span className="core-number">1</span><span className="core-activity">Research & Evaluation</span>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="border-r-dashed border-white flex">
                            <span className="core-number">2</span><span className="core-activity">Analysis & Reporting</span>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="border-r-dashed border-white flex">
                            <span className="core-number">3</span><span className="core-activity">Geospatial data capture</span>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="border-r-dashed border-white flex">
                            <span className="core-number">4</span><span className="core-activity">Market intelligence</span>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="border-r-dashed border-white flex">
                            <span className="core-number">5</span><span className="core-activity">Training</span>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="border-r-dashed border-white flex">
                            <span className="core-number">6</span><span className="core-activity">Sustainability advisory</span>
                        </div>
                    </div>
                </Row>
            </div>
        </section>
    )
}